<script>
import { toRefs, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { maska } from 'maska'
import { internalZipLookup } from '@/util/validations'

export default {
  name: "ZipLander",

  directives: {
    maska
  },

  props: {
    questionText: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const questionText = props.questionText

    const store = useStore()

    const state = reactive({
      zip: {
        value: '',
        error: false
      },
    })


    const getZipData = async () => {
      //this line of code is to ensure that if the correct zip is entered and the user keeps trying to type, an error will not display
      if (state.zip.value.length > 5) return

      //check if zip length is good
      if (state.zip.value.length < 5) {
        state.zip.error = true
        return
      }

      //if zip length is okay, we can use internal lookup to validate zip further
      const zipData = await internalZipLookup(state.zip.value)
      state.zip.error = zipData.zipError

      if (state.zip.error) return

      return zipData
    }

    const validateZip = async () => {
      const zipInfo = await getZipData(state.zip.value)
      if (!zipInfo || zipInfo.zipError) return

      const { county, city, stateCode } = zipInfo
      const answers = [
        {field: 'county', value: county},
        {field: 'city', value: city},
        {field: 'stateCode', value: stateCode},
        {field: 'zip_code', value: state.zip.value}
      ]

      //store in local storage
      const localStorage = window.localStorage
      await localStorage.setItem('zip_data', JSON.stringify(answers))

      window.location.href = '/quote-way'
    }

    const leaveBehindUrl = computed(() => {
      const searchParams = new URLSearchParams(location.search)
      searchParams.set('zip_code', state.zip.value)
      searchParams.set('slb', '1')

      if (store.state.fields['ef_transaction_id']) {
        searchParams.set('ef_transaction_id', store.state.fields['ef_transaction_id'])
      }

      if (state.zip.error) return 'javascript:;'
      return location.pathname + '?' + searchParams.toString()
    })

    const proceedToNextPage = async (event) => {
      if (!state.zip.value || state.zip.error) {
        event.preventDefault()
        return
      }

      // store.commit('quote_way/SET_SHOW_LEAVE_BEHIND_ADS', true)

      window.open(
        '/quote-way',
        '_blank'
      )
    }

    return {
      validateZip,
      leaveBehindUrl,
      ...toRefs(state),
      questionText,
      proceedToNextPage
    }
  }
}
</script>

<template>
    <div class="funeral-zip-container">
      <div class="row">
        <h2>{{questionText}}</h2>
      </div>

      <div class="row">
        <input
          class="form-input zip-input"
          :class="{'zip-error' : zip.error}"
          type="text"
          placeholder="Enter Zip"
          v-maska="'#####'"
          v-model="zip.value"
        >

        <div class="error-container">
          <p class="error-text" v-if="zip.error">Please enter a valid zip code to proceed.</p>
        </div>
      </div>

      <div class="row">
        <!--<a :href="leaveBehindUrl" target="_blank" @click="proceedToNextPage($event)"></a>-->
        <button
          class="main-button main-button-w"
          @click="validateZip"
          :disabled="zip.error"
        >
          Continue
        </button>
      </div>
    </div>
</template>

<style scoped lang="scss">
.funeral-zip-container {
  max-width: 400px;
  width: 100%;
  margin: 10px auto;
  .row {
    margin: 10px 0;
    width: 100%;
  }
  h2 {
    font-family: sans-serif;
    text-align: left;
    font-size: 28px;
    color: #2a7343;
    font-weight: bold;
  }

  .form-input {
    font-size: 20px;
  }

  .form-input.zip-input {
    background-color: #f3f4f4;
    height: 45px;
  }
  .form-input.zip-input.zip-error {
    background-color: #FAD2D2;
  }

  .main-button {
    height: 45px;
    font-size: 20px;
  }
}
</style>