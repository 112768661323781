<script>
import {computed, reactive, toRefs} from 'vue'
import { useStore } from 'vuex'
import ZipLander from '@/components/home_v2/ZipLander'
import LeaveBehindAds from '@/components/shared/LeaveBehindAds'

export default {
  name: "FuneralStatistics",
  components: {
    ZipLander,
    LeaveBehindAds
  },
  setup() {
    const store = useStore()
    const showLeaveBehindAds = computed(() => store.state['quote_way'].showLeaveBehindAds)

    return {
      showLeaveBehindAds,
    }
  }
}
</script>

<template>
  <section>
    <div class="section-container" v-if="!showLeaveBehindAds">
      <div class="funeral-statistics">
        <h2>Data From 660 Funeral Homes Reveal Some Surprising Truth</h2>
        <img src="../../assets/img/bench.jpg" alt="bench">
        <div class="funeral-text-container">
          <p class="small-text">
            <strong>
              An analysis of 660 funeral homes found that the average traditional funeral
              costs $12,094 to $13,616.1
            </strong>
              Because of this heavy expense, staying informed
              about one’s options is very important.
          </p>
          <br>
          <p class="small-text">
            Paying for a funeral or cremation can be a huge source of stress for
            grieving families. Because we don't know what the future holds, we need to
            prepare for it the best we're able. This includes being informed about
            funeral costs in advance, and understanding how to save on those costs.
          </p>
          <br>
          <p class="small-text">
            One option is to set up a prepaid funeral plan with a funeral home. However,
            there are several risks that come with doing this: what you purchased may
            not be available at the time of the funeral. In addition, any changes may
            invalidate your plan. For example, if you move, you may not be able to
            transfer the plan. Furthermore, prepaid funeral plans aren't regulated to
            the extent that final expense insurance is.
          </p>
          <br>

          <ZipLander :question-text="'Enter zip code to get a final expense rate'"/>

          <p class="small-text">
            A better option is to lock in a discounted final expense insured rate ahead
            of time. This will safeguard your family from pricing they cannot afford,
            while saving them money. Even if you have life insurance, you'll want final
            expense insurance for several reasons: most people underestimate the amount
            of life insurance they need, term life insurance expires, and final expense
            insurance does not have the same strict health requirements.
          </p>
          <br>
          <p class="small-text">
            Final expense coverage is a type of whole life insurance. This means that
            large financial institutions secure the coverage amount, and the government
            enforces the payout. Additionally,
            <strong>
              there's often no required medical exam,
              many pre-existing medical conditions are allowed, and coverage doesn't
              expire.
            </strong>
          </p>
          <br>
          <p class="small-text">
            Final expense insurance is cheap because it’s easy to setup and
            straightforward. You’re able to lock in a price that works for you—it only
            costs about a few dollars a month, and it covers about $5,000 to $25,000,
            depending on the plan that you pick. Final expense plans also come with a 30
            day money back guarantee—which you can pick instantly.
          </p>
          <br>
          <p class="small-text">
            What's the next step? Simply complete a quick questionnaire to get final
            expense insurance rates from an available company. No more bargain shopping,
            talking to endless insurance representatives who try to sweet talk you into
            coverage you don’t need, or wasting money.
          </p>
          <br>
          <p class="small-text">
            <strong class="large-strong-font">
              Dozens of life insurers provide final expense coverage. Which insurer will
              give you the best rate?
            </strong>
          </p>
        </div>

        <div class="funeral-steps-process">
          <p>
            <strong>Step 1)</strong>
            Enter your zip code
          </p>
          <p>
            <strong>Step 2)</strong>
            On the next page complete the 60 second questionnaire
          </p>
          <p>
            <strong>Step 3)</strong>
            You'll have the opportunity to compare a low rate in your area -- which can add up to hundreds or thousands in savings.
          </p>
        </div>

        <ZipLander :question-text="'What is your zip code?'"/>
      </div>
    </div>
    <LeaveBehindAds v-if="showLeaveBehindAds"/>
  </section>
</template>

<style scoped lang="scss">
section {
  margin-top: 60px;
  width: 100%;
  .funeral-statistics {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    max-width: 600px;
    margin: 0 auto;
    box-shadow: rgba(0, 0, 0, 0.25) 0 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0 0 0 1px inset;
    padding: 20px;
    background: white;
    border-radius: 5px;
    .funeral-text-container {
      margin: 10px 0;
    }

    h2 {
      font-family: sans-serif;
      text-align: center;
      font-size: 32px;
      color: #2a7343;
      font-weight: bold;
      margin-bottom: 10px;
    }

    p {
      line-height: 22px;
      font-size: 16px;
    }

    p strong.large-strong-font {
      font-size: 25px;
    }

    img {
      width: 100%;
    }
  }

  .error-container {
    height: 15px;
  }
}

@media screen and (max-width: 425px) {
  section .funeral-statistics h2 {
    font-size: 28px;
  }
}

@media screen and (max-width: 320px) {
  section .funeral-statistics h2 {
    font-size: 32px;
  }
}
</style>